import React from 'react'
import Layout from '../components/layout'
import Head from '../components/head'
import Painter from '../components/painter'
import {graphql, useStaticQuery, StaticQuery} from 'gatsby'
import PainterWonModal from '../components/painter_won_modal'

class IndexPage extends React.Component {
    
    constructor() {
        super()
        this.state = {
            won: false
        }
    }
    
    render() {
        return (
            <StaticQuery
                query={graphql`
                query {
                    allColorIndexJson {
                        nodes {
                            value
                        }
                    }
                    allDistinctPixelsJson {
                        nodes {
                            value
                        }
                    }
                }
                `}
                render={(data) => (
                    <Layout>
                        <Head title="Home" />
                        <Painter
                            colorIndex={data.allColorIndexJson.nodes[0].value}
                            colors={data.allDistinctPixelsJson.nodes[0].value}
                            gameWon={() => this.gameWon()}
                        ></Painter>
                        {(this.state.won) ? <PainterWonModal /> : null}
                    </Layout>
                )}/>
        )
    }

    gameWon() {
        this.setState({...this.state, won: true});
    }
}

export default IndexPage