import React from 'react'
import colorswatchStyles from './colorswatch.module.css'

const ColorSwatch = ({ color, isActive, onClick }) => {
    const style = {
        backgroundColor: color,
        opacity: isActive ? 1 : 0.5
    }
    return (
        <div className={colorswatchStyles.swatch} style={style} onClick={onClick}>
            
        </div>
    )
}

export default ColorSwatch