import React from 'react'
import Pixel from './pixel'
import gridStyles from './grid.module.css'

const Grid = ({ pixelData, fillItem, colors }) => {
    return (
        <div className={gridStyles.grid}>
            {pixelData.map((item, i) => <Pixel onClick={() => fillItem(i)} colorIndex={item.colorIndex} colors={colors} index={i} />)}
        </div>
    )
}

export default Grid