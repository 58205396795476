import React from 'react'
import Grid from './grid'
import Colorchooser from './colorchooser'
import painterStyles from './painter.module.css'

class Painter extends React.Component {
    
    constructor (props) {
        super(props);
        this.state = {
            activeColor: 0,
            pixelData: Array.apply(null, {
                length: 64
            }).map(function () {
                return {
                    colorIndex: null
                };
            }),
            colors: props.colors,
            gameWon: false
        }
        console.log(this.state);
    }
    
    selectColor(index) {
        this.setState({
            ...this.state,
            activeColor: index
        })
    }

    fillItem(index) {
        this.setState({
            ...this.state,
            pixelData: this.state.pixelData.map((data, i) => {
                if(i === index) {
                    return {
                        colorIndex: this.state.activeColor
                    }
                }
                else return data;
            })
        })
        this.checkValidity();
    }

    checkValidity() {
        let numValid = 0;
        for(let i=0; i<this.props.colorIndex.length; ++i) {
            console.log(this.state.pixelData[i].colorIndex, this.props.colorIndex[i]);
            if(this.state.pixelData[i].colorIndex === this.props.colorIndex[i]) {
                numValid++;
            }
        }
        if(numValid === 63) {
            this.props.gameWon();
        }
    }
    
    render () {
        return (
            <div className={painterStyles.container}>
                <Colorchooser colors={this.state.colors} activeColor={this.state.activeColor} selectColor={(index) => this.selectColor(index)}></Colorchooser>
                <Grid pixelData={this.state.pixelData} colors={this.state.colors} fillItem={(index) => this.fillItem(index)}></Grid>
            </div>
        )
    }
}

export default Painter