import React from 'react'
import styles from './painter_won_modal.module.css'
import gif from '../assets/girl.gif';
import { Link } from 'gatsby';

const PainterWonModal = () => {
    return (
        <div className={styles.modalBackground}>
            <div className={styles.modal}>
                <h1>CONGRATS!</h1>
                <img src={gif}></img>
                <Link to="gif1" class="button">Get your GIFt</Link>
            </div>
        </div>
    )
}

export default PainterWonModal