import React from 'react'
import colorchooserStyles from './colorchooser.module.css'
import ColorSwatch from './colorswatch'

const Colorchooser = ({ colors, activeColor, selectColor }) => {

    return (
        <div className={colorchooserStyles.colorchooser}>
            {colors.map((color, i) => 
                <ColorSwatch
                    color={color} 
                    isActive={activeColor===i} 
                    onClick={(e) => selectColor(i)}
                ></ColorSwatch>
            )}
        </div>
    )
}

export default Colorchooser