import React from 'react'
import pixelStyles from './pixel.module.css'

const Pixel = ({ index, colorIndex, onClick, colors }) => {
    console.log(colors, colorIndex);
    const style = {
        background: colors[colorIndex] || (index % 2 === 0 ^ (Math.floor(index/8) % 2 === 0) ? 'rgba(255,255,255,0.05)' : 'transparent')
    }
    return (
        <div onClick={onClick} className={pixelStyles.square} style={style}>
            <div className={pixelStyles.content}>
                { (index === 26) ? index+1 : null }
            </div>
        </div>
    )
}

export default Pixel