import React from 'react'
import '../styles/index.css';
import layoutStyles from './layout.module.css';
import Footer from './footer';

const Layout = props => {
    return (
        <div className={layoutStyles.container}>
            {props.children}
        </div>
    )
}

export default Layout